.prompts-tester {
  margin: 40px;
  gap: 20px;
  background: var(--bg_light_1);

  textarea {
    width: 100%;
    height: 300px;
  }

  li.used {
    font-weight: bold;
  }

  .prompt-side {
    width: 30%;
  }

  .tester {
    width: 70%;

    .inputs {
      width: 200px;
      gap: 10px;
    }

    .response {
      background: var(--bg_light_1);
      padding: 10px;
      border: 1px solid;
      border-radius: 8px;
    }
  }

  .chat {
    border: 1px solid;
    border-radius: 8px;
  }
}
