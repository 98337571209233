.voice-over .settings-tooltip {
  opacity: 1;
  background: transparent;
  padding: 0;
}

.voice-over .settings-panel {
  justify-content: end;
}

.voice-over .settings-icon {
  height: 24px;
  align-self: center;
  margin-left: var(--scale_4);
  padding: var(--scale_1);
  border-radius: var(--border_radius);
  cursor: pointer;
}

.voice-over .settings-icon.active {
  background: var(--bg_light_enabled);
}

.voice-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.bar {
  background: var(--brand-color);
  bottom: 1px;
  height: 3px;
  width: 10px;
  margin: 0 4px;
  border-radius: 5px;
}

.bar.play {
  animation: sound 0ms -600ms linear infinite alternate;
}

@keyframes sound {
  0% {
    opacity: 0.35;
    height: 3px;
  }
  100% {
    opacity: 1;
    height: 70px;
  }
}

.bar:nth-child(1) {
  left: 1px;
  animation-duration: 474ms;
}
.bar:nth-child(2) {
  left: 15px;
  animation-duration: 433ms;
}
.bar:nth-child(3) {
  left: 29px;
  animation-duration: 407ms;
}
.bar:nth-child(4) {
  left: 43px;
  animation-duration: 458ms;
}
.bar:nth-child(5) {
  left: 57px;
  animation-duration: 400ms;
}
.bar:nth-child(6) {
  left: 71px;
  animation-duration: 427ms;
}
.bar:nth-child(7) {
  left: 85px;
  animation-duration: 441ms;
}
.bar:nth-child(8) {
  left: 99px;
  animation-duration: 419ms;
}
.bar:nth-child(9) {
  left: 113px;
  animation-duration: 487ms;
}
.bar:nth-child(10) {
  left: 127px;
  animation-duration: 442ms;
}
