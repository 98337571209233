.voice-settings {
  margin: auto;
  display: flex;
  flex-direction: column;
  background: white;
  color: black;
  padding: var(--scale_2) var(--scale_4);
  border-radius: var(--border_radius);
  box-shadow: var(--box_shadow);
}

.voice-settings .row {
  line-height: 40px;
}

.voice-settings .row .key {
  font-weight: bold;
  margin-right: var(--scale_4);
}
