.feedback-error {
  margin: 50px auto;
  width: fit-content;
}

.feedback-error img {
  width: 200px;
  display: block;
  margin: auto;
}

.feedback-error button {
  background: var(--brand-color);
}

.feedback {
  flex-direction: column;
  gap: 10px;
  width: 800px;
  margin: auto;
  line-height: 22px;

  h4 {
    margin: 10px 0;
  }

  .buttons {
    justify-content: start;
    gap: var(--scale_2);
    margin: var(--scale_4) 10px;
  }

  .note {
    padding: 20px 30px 20px 20px;
    background: #ff6c6c47;
    color: black;
    border-radius: var(--border_radius);
    align-items: center;
    text-align: center;
    margin-bottom: vaR(--scale_2);
  }

  .note .label {
    padding: 20px 30px 20px 20px;
    font-size: 20px;
    font-weight: bold;
  }

  h3 {
    margin-bottom: var(--scale_2);
  }

  .written {
    flex-direction: column;
    gap: var(--scale_3);
    height: 100%;
    overflow: scroll;
  }

  .box {
    box-shadow: unset;
    padding: var(--scale_3);
    border-radius: var(--border_radius);
    background: rgb(0 208 213 / 14%);
  }

  .zero {
    //border-color: #ff9b37;

    .score {
      background: #ff9b37;
    }
  }

  .one {
    //border-color: #fbffb1;

    .score {
      background: #fbffb1;
    }
  }

  .two {
    //border-color: #b1ffcb;

    .score {
      background: #b1ffcb;
    }
  }

  .three {
    //border-color: #b5a8ff;

    .score {
      background: #b5a8ff;
    }
  }

  .criteria-title {
    font-size: 18px;
    align-items: center;
    padding: var(--scale_2) var(--scale_4);
    border-top-left-radius: var(--border_radius);
    border-top-right-radius: var(--border_radius);

    h4 {
      align-items: center;

      svg {
        margin-right: 8px;
        color: var(--color-action);
      }
    }

    .score {
      font-weight: bold;
      border-radius: 100%;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
    }

    .score-wrapper {
      flex-direction: column;
      text-align: center;
      align-items: center;
      text-transform: capitalize;
      font-weight: 500;
    }
  }

  .reason {
    padding: 0 var(--scale_4);
    margin-top: 10px;
    max-height: 0;
    overflow: hidden;

    &.show {
      max-height: unset;
    }

    li {
      padding-left: 30px;
      margin-bottom: 10px;
      line-height: 19px;
    }
  }

  h5 {
    margin: var(--scale_2) 0;
  }

  .pdf-frame {
    padding: 0 10px;
  }

  .text {
    padding: var(--scale_2);
    padding-bottom: var(--scale_5);
  }

  .overall-summery {
    width: 250px;
    margin: 20px auto;

    .list {
      display: flex;
      flex-direction: column;
    }
  }
}

.feedback-example {
  margin: auto;

  .promo {
    margin-bottom: 50px;

    img {
      height: 300px;
    }
  }
}
