.homepage {
  letter-spacing: 0.3px;

  h1 {
    margin-top: 80px;
    line-height: 60px;
  }

  .homepage-inner {
    margin: 50px auto;
  }

  img.logo-cover {
    margin-top: 20px;
    height: 80px;
  }

  h2 {
    text-align: center;
  }

  .description {
    font-weight: 300;
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 120px;
    max-width: 500px;
  }

  .info {
    justify-content: space-between;
    margin: auto;
  }

  .banner {
    margin: 40px auto;
    position: relative;

    .banner-container {
      padding: 40px;
      border-radius: var(--border_radius);
      margin: auto;
    }

    .content {
      font-size: 20px;
      padding-right: 40px;
      line-height: 28px;
      color: var(--color-black);
      width: 500px;

      button {
        margin-top: 50px;
      }
    }

    .example .box {
      background: white;
      padding: var(--scale_4);
      border-radius: 10px;
      width: 350px;
      font-size: 20px;
      box-shadow: 20px 20px 50px rgba(101, 101, 101, 0.55);
      height: 140px;
      line-height: 24px;
      margin-top: 60px;
    }

    .example button {
      margin-top: 20px;
    }

    .chat-container {
      width: 500px;
      background: white;
      border-radius: var(--border_radius);
      box-shadow: var(--box_shadow);
      height: 100%;

      .message-container {
        gap: 10px;
      }
    }

    img {
      width: 400px;
    }
  }

  .why {
    .why-container {
      justify-content: center;
      align-items: center;
    }

    img {
      width: 400px;
    }

    .content {
      padding-right: 40px;
      line-height: 28px;
      color: var(--color-black);
      width: 500px;
      font-size: 20px;
    }
  }

  .maximize {
    margin: 40px auto 80px auto;
    position: relative;

    .maximize-internal {
      background: #f8c8b485;
      padding: 40px;
      border-radius: var(--border_radius);
      margin: auto;
    }

    .content {
      width: 550px;
      font-size: 20px;
      line-height: 50px;
    }

    img {
      width: 450px;
      position: absolute;
      right: 60px;
      top: 50px;
      background: #d5f1f1;
      border-radius: 20px;
    }

    li {
      margin-bottom: 20px;
    }
  }

  .footer {
    border-top: 1px solid var(--brand-color);
    padding: 20px;
    background: var(--bg_light_1);
  }

  .footer .inner {
    padding: 10px 0;
    margin: auto;
    max-width: 1024px;
    justify-content: left;
    gap: 10px;
  }

  .example-eval {
    margin: 70px auto;
    border-radius: 25px;

    h2 {
      margin: 10px;
    }

    .banner {
      width: 800px;
      margin: 20px auto;
      font-size: 22px;
      align-items: center;

      .button {
        display: block;
        margin-top: 40px;
      }
    }

    img {
      height: 250px;
    }
  }

  @media only screen and (max-width: 600px) {
    .description {
      margin-bottom: 60px;
    }
    .homepage-inner {
      padding: 0 20px;
    }

    h2 {
      margin: 20px 0;
    }

    .banner .content {
      padding: unset;
      margin-bottom: var(--scale_2);
    }

    .banner .example .box {
      height: 180px;
    }

    .banner .example button {
      width: 100%;
    }

    .banner {
      margin: unset;
      width: unset;
      height: unset;
      padding-bottom: unset;

      .banner-container {
        padding: var(--scale_2);
      }

      .chat-container {
        position: relative;
        width: 100%;
        top: unset;
        right: unset;
      }

      .content {
        width: unset;
        margin: 30px 0;
        text-align: center;
        padding: 2px;
      }
    }

    .why {
      .why-container {
        text-align: center;
      }

      .content {
        padding: unset;
        width: auto;
      }
    }

    .maximize {
      width: unset;

      .maximize-internal {
        padding: 10px;
        margin: 20px;

        .content {
          width: unset;
        }
      }
    }

    .example-eval {
      padding: unset;
      text-align: center;

      button {
        margin: 10px auto;
      }

      img {
        width: 80%;
        margin: 20px auto;
      }

      .banner {
        width: 100%;
      }
    }

    .feedback {
      width: 100%;
      .reason li {
        padding: unset;
      }
    }
  }
}
