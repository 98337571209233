.for-students {
  margin-bottom: 30px;

  .text {
    max-width: 700px;
    font-size: 20px;
    line-height: 28px;
  }

  h2 {
    text-align: center;
  }

  .content {
    font-size: 20px;
    line-height: 28px;
  }

  .quotes {
    padding: 40px;

    .item {
      .content {
        color: var(--brand-color);
      }

      .name {
        font-size: 22px;
        font-weight: bold;
        margin-top: 10px;
      }
    }
  }

  .image {
    padding: 20px;
  }

  .why {
    .why-inner {
      background: #f8c8b485;
      padding: 40px;
      border-radius: var(--border_radius);
      max-width: 800px;
      width: auto;
      margin: auto auto 30px;
    }
  }

  @media only screen and (max-width: 600px) {
    .why {
      .why-inner {
        width: auto;
        padding: 20px;
      }
    }
  }
}
