.info {
  h3 {
    color: var(--third_color);
    text-align: center;
    font-size: 20px;
  }

  .item {
    padding: var(--scale_4);
    border-radius: 13px;
    background: #00c6c926;
    width: 330px;
    font-size: 18px;
    text-align: center;
  }

  .item img {
    height: 200px;
    display: block;
    margin: auto auto 20px;
  }

  .item .row {
    font-size: 20px;
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
    .items-list {
      flex-direction: column;
      gap: var(--scale_5);
    }

    .item {
      width: auto;
    }
  }
}
