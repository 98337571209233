.header-container {
  background-color: var(--bg_light_1);
}

.app .header-container {
  background: unset;
}

.header {
  padding: var(--scale_4) 0;
  color: var(--main_color);
  justify-content: space-between;
  background-color: var(--bg_light_1);
  z-index: 9;
  width: 100%;
  letter-spacing: 0.3px;
}

.header .header-container {
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

.header.float-header {
  border-bottom: 1px solid rgb(218, 220, 224);
}

.header .head {
  display: flex;
  justify-content: right;
  background: var(--bg_light_1);
  padding: var(--scale_4);
}

.header .logo {
  margin-left: 20px;
}

.header a {
  align-items: center;
}

.header a.logo {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  opacity: 1;
  align-items: center;
}

.header .menu {
  align-items: baseline;
  padding: 2px 10px;
}

.header .menu.loading {
  opacity: 0;
}

.header .logo img {
  height: 28px;
}

.header hr {
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  height: auto;
  border-right-width: thin;
  align-self: stretch;
  margin: 0 var(--scale_3);
}

.header .buttons-menu {
  gap: var(--scale_2);
  align-items: baseline;
}

.header .item {
  font-size: 16px;
  line-height: 24px;
  padding: 8px 20px;
  color: var(--color-black);
}

.header.app .item.session {
  font-weight: 500;
  border-bottom: 3px solid var(--brand-color);
}

.header .item.logout {
  flex-direction: column;
  justify-content: center;
  display: flex;
  padding: unset;
  height: 38px;
  width: 38px;
  background: var(--third_color);
  color: white;
  border-radius: 100%;
  text-align: center;
  margin-left: var(--scale_4);
  text-transform: capitalize;
}

.header .logout-tooltip button {
  margin-top: var(--scale_2);
}

.header .item.action {
  color: var(--second_color);
  background-color: var(--brand-color);
  border-radius: 6px;
  padding: 6px 22px;
  line-height: 28px;
  font-size: 14px;
}

.header .item.contact {
  border: 1px solid var(--color-gray);
  color: var(--color-black);
  border-radius: 6px;
  padding: 6px 22px;
  line-height: 28px;
  font-size: 14px;
}

.page {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.outlet {
  background: var(--bg_light_1);
}

.app-session .outlet {
  height: 100%;
}

.page.homepage .outlet {
  overflow: scroll;
}

.page.app {
  background: rgb(0, 198, 201);
  background: linear-gradient(
    45deg,
    rgba(0, 198, 201, 0.4) 0%,
    rgba(94, 206, 209, 0.2) 40%,
    rgba(21, 200, 203, 0.08) 50%,
    rgba(0, 198, 201, 0) 100%
  );
  overflow: hidden;
}

.page.app .outlet {
  background: unset;
  overflow: scroll;
}

.page.app.session-objectives {
  padding: unset;
}

.mobile-menu {
  font-size: 30px;
  padding: 4px;
}

#mobile-menu-tooltip {
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: var(--box_shadow);
}

.header {
  @media only screen and (max-width: 600px) {
    & {
      padding: 0;
    }

    .header-container {
      padding: 5px 0;
    }

    .menu {
      align-items: unset;
    }

    .logo {
      margin-left: 8px;
      margin-top: 4px;
    }
  }

  .item.logout {
    margin: unset;
  }
}
