.contact {
  height: fit-content;
}

.contact .submit {
  text-align: center;
  font-size: 34px;
  width: 300px;
  margin: 40px;
  color: var(--brand-color);
  font-weight: bold;
}

.contact .contact-container {
  justify-content: space-between;
  max-width: 900px;
  margin: 80px auto;
}

.contact .contact-info {
  width: 400px;
  font-size: 20px;
  background: #a0e2e352;
  border-radius: 22px;
  padding: 10px 30px;
}

.contact .contact-info img {
  width: 400px;
  border-radius: var(--border_radius);
}

.contact .contact-info h2 {
  margin-bottom: 40px;
}

.contact .contact-info a {
  color: var(--color-action);
}

.contact h2 {
  margin: 20px;
}

.contact .contact-form {
  width: 350px;
  padding: 20px;
  font-size: 18px;
  border-radius: var(--border_radius);
  height: fit-content;
}

.contact .contact-form .split-row {
  display: flex;
  gap: 10px;
}

.contact .contact-form .input {
  margin-bottom: var(--scale_4);
}

.contact .contact-form .input label {
  display: block;
  margin-bottom: var(--scale_2);
  font-size: 16px;
  font-weight: 500;
}

.contact .contact-form .input input {
  border-radius: var(--border_radius);
  font-size: 14px;
  width: -webkit-fill-available;
  padding: 14px 0 14px 14px;
  border: 1px solid transparent;
  background: #00c6c978;
}

.contact .contact-form .input input:focus {
  border-color: var(--brand-color);
}

.contact .contact-form .input textarea {
  width: -webkit-fill-available;
  resize: vertical;
  border-radius: var(--border_radius);
  background: #00c6c978;
}

.contact .contact-form .input .dropdown {
  font-size: 14px;
  line-height: 46px;
  padding-left: 14px;
  cursor: pointer;
}

.contact .contact-form .input .dropdown .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.contact .contact-form .input .dropdown:hover .dropdown-content {
  display: block;
}

.contact .contact-form .input .dropdown .dropdown-content > div {
  padding: var(--scale_2);
  cursor: pointer;
}

.contact .contact-form .input .dropdown .dropdown-content > div:hover {
  background: var(--brand-color);
}

@media only screen and (max-width: 600px) {
  .contact {
    overflow: scroll;
    height: 100%;
    margin: var(--scale_3);

    .contact-container {
      margin: auto;
    }

    .contact-info {
      margin-bottom: var(--scale_4);
      padding: 20px;
      width: auto;
    }

    .contact-form {
      box-shadow: unset;
      margin: unset;
      width: -webkit-fill-available;
      margin-bottom: 20px;
    }
  }
}
