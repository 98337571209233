button.theme-button {
  font-weight: 500;

  transition:
    background 0.1s ease-in,
    background 0.1s ease-in;

  &:disabled {
    opacity: 0.6;
  }

  &.button-small {
  }

  &.button-medium {
    padding: 10px 15px;
    font-size: 18px;
  }

  &.button-large {
    padding: 20px 40px;
    font-size: 24px;
    margin: 40px auto;
  }

  &.button-dark {
    background: var(--third_color);
    color: white;

    &.action-color {
      background: var(--color-action);
    }

    &:hover {
      background: #000710;
    }
  }

  &.button-brand {
    background: var(--brand-color);
    color: var(--third_color);

    &:hover {
      background: rgb(0, 169, 171);
    }
  }

  &.button-transparent {
    background: transparent;
    font-weight: bold;
    color: rgb(0, 169, 171);

    &.action-color {
      color: var(--color-action);
    }

    &:hover {
      color: #000710;
    }
  }

  .button-container {
    flex-direction: row;
    gap: var(--scale_3);
    justify-content: space-between;
  }

  .button-container {
    .icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
