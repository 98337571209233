.session-page {
  height: 100%;
  width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.inputs {
  margin-top: auto;
  display: flex;
  background: white;
  padding: var(--scale_6);
  border-radius: var(--border_radius);
  margin-bottom: var(--scale_3);
}

.inputs textarea {
  margin-left: var(--scale_4);
  border-radius: var(--border_radius);
  border: 1px solid #e4e4e4;
}

.chat {
  padding: var(--scale_4) var(--scale_6);
  margin: var(--scale_2) 0;
  overflow: scroll;
  height: 100%;
}

.chat .message-container {
  gap: 40px;
  margin-bottom: var(--scale_4);

  &:last-child {
    margin-bottom: 0;
  }
}

.chat .message-container img.avatar {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.chat .message {
  padding: var(--scale_3) var(--scale_4);
  border-radius: var(--border_radius);
  width: 100%;
}

.chat .user {
  background: var(--main_color);
  color: var(--second_color);
  margin-right: auto;
}

.chat .computer {
  background: #5eced163;
  color: black;
}

.chat .loading {
  color: black;
  width: -webkit-fill-available;
}

.chat .loading span {
  animation: opacity-blink 1.5s steps(2) infinite;
  font-weight: bold;
  font-size: 20px;
}

.shimmer {
  background: var(--brand-color);
  background-image: linear-gradient(
    to right,
    var(--brand-color) 0%,
    rgba(146, 209, 211, 0.69) 40%,
    rgba(169, 213, 215, 0.66) 60%,
    var(--brand-color) 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;

  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: ease-in;
}

@keyframes opacity-blink {
  0% {
    opacity: 0;
  }
}

@keyframes opacity-fade {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  50% {
    background-position: 468px 0;
  }

  100% {
    background-position: -468px 0;
  }
}

.speaking-button {
  font-size: 20px;
  height: 100px;
  width: 100px;
  display: block;
  text-align: center;
  background: var(--color-action);
  color: white;

  &:hover {
    background: #001088;
  }
}

textarea.draft-message {
  flex-grow: 1;
  height: 80px;
  padding: var(--scale_3);
}

textarea.draft-message:focus {
  border: 1px solid var(--brand-color);
}

.speaking-button.send {
  background: green;
  color: white;
}

.speaking-button.send:disabled {
  background: var(--bg_light_3);
}

.session-controls {
  margin-left: var(--scale_4);
  display: flex;
  flex-direction: column;
}

.session-controls button {
  font-size: 20px;
  height: 100px;
  width: 100px;
  background: var(--button_color);
  color: white;
}

.session-controls button:hover {
  background: #102b2c;
}

.new-session-form {
  width: 900px;
  margin: auto;
  gap: 20px;
  justify-content: left;

  .clients-list {
    padding: 0 20px;
    width: 200px;

    .select-client {
      border-left: 4px solid var(--main_color);
      padding: var(--scale_2) var(--scale_3);
      margin-bottom: var(--scale_1);
      cursor: pointer;

      &.selected {
        border-color: var(--brand-color);
      }
    }
  }
}

.new-session-form .row {
  gap: var(--scale_3);
  justify-content: start;
}

.new-session-form .field {
  margin: var(--scale_1) 0;
}

.new-session-form input,
.new-session-form textarea,
.new-session-form select {
  display: block;
  border-radius: var(--border_radius);
  background: white;
  font-size: 16px;
  width: 100%;
  padding: var(--scale_3);
  border: 1px solid var(--brand-color);
  width: -webkit-fill-available;
}

.new-session-form label {
  padding: var(--scale_2);
  font-weight: bold;
  display: block;
}

.new-session-form .save {
  margin: var(--scale_3) 0;
}

.new-session-form .save button {
  background: var(--brand-color);
}

.loader {
  width: max-content;
  margin: var(--scale_4) auto;
  font-size: 34px;
  font-weight: bold;
  text-align: center;
}

.loader img {
  width: 300px;
  margin-top: var(--scale_2);
}

.loader .small-text {
  font-size: 80%;
}

.loader .progress-bar {
  width: 100%;
  border: 2px solid var(--brand-color);
  height: 30px;
  border-radius: 18px;
  margin-top: var(--scale_3);
  position: relative;
  padding: 3px;
}

.loader .progress-bar .inner {
  height: 100%;
  width: 0;
  background: var(--brand-color);
  border-radius: 22px;
  transition: width 500ms cubic-bezier(0.32, 0.95, 0.62, 0.97);
  animation: opacity-fade 3s infinite;
  overflow: hidden;
}

.disclaimer {
  max-width: 800px;
  overflow: scroll;
  margin: auto;
}

.disclaimer .content {
  height: 400px;
  overflow: scroll;
  background: white;
  padding: var(--scale_4);
}

.disclaimer button {
  margin-top: var(--scale_4);
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}

.disclaimer input {
  border-top-left-radius: var(--border_radius);
  border-bottom-left-radius: var(--border_radius);
}

.objectives {
  margin: auto;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 30px;
  height: 100%;

  .row {
    padding: 20px 60px;
  }

  .messages-left {
    width: fit-content;
    border-radius: var(--border_radius);
    text-align: center;
    margin: 60px 0;
    color: var(--third_color);
    font-size: 30px;
  }

  .messages-left span {
    font-weight: bold;
    color: black;
  }

  h2,
  h3 {
    button {
      display: block;
      padding-left: unset;
      padding-right: unset;
    }
  }

  h2 {
    font-size: 28px;
    margin: 10px 0 20px 0;
  }

  h3 {
    justify-content: start;
    gap: var(--scale_4);
    align-items: center;
  }

  span.fydelity {
    color: var(--third_color);
    font-weight: bold;
  }

  .side {
    background: white;
    overflow: scroll;

    &.left {
      width: 35%;
    }

    &.right {
      width: 65%;
    }

    &.no-padding {
      padding: unset;
    }

    .start-session {
      padding: 60px;
    }
  }

  .side.left {
    background: var(--brand-color);
    color: black;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 1px;

    h1 {
      padding: 60px 0 20px 0;
      text-align: center;
      line-height: 55px;
    }
  }

  .buttons {
    position: relative;
    animation-name: jump;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    visibility: hidden;

    &.show {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s ease-in;
    }

    @keyframes jump {
      0% {
        bottom: 0;
      }

      50% {
        bottom: 30px;
      }

      100% {
        bottom: 0;
      }
    }
  }
}

.skills-selector {
  .intro {
    padding: 20px 60px 10px 60px;
  }

  .skills-list {
    padding: 10px 60px 0 60px;
    background: var(--bg_light_3);
    flex-direction: column;
  }

  .skill-icon {
    color: var(--color-action);
  }

  .skill {
    border-bottom: 3px solid;

    .text {
      max-height: 0;
      overflow: hidden;
      transition: none;
      margin-bottom: var(--scale_3);

      button {
        align-content: center;
        gap: var(--scale_3);
        padding: var(--scale_2) 0;
      }
    }

    h4 {
      align-items: center;
      justify-content: left;
      gap: var(--scale_2);
      cursor: pointer;

      svg.icon {
        transition: rotate 0.3s ease-in;
      }

      svg.lock {
        color: var(--brand-color);
      }
    }

    &.selected {
      svg.icon {
        rotate: -90deg;
      }

      .text {
        max-height: 300px;
        transition: max-height 0.3s ease-in;
      }
    }

    .coming-soon {
      font-size: 12px;
      font-style: italic;
      margin-left: auto;
    }
  }
}

.reset-modal {
  h2 {
    margin-top: unset;
  }

  .buttons {
    gap: 14px;
    justify-content: start;

    button {
      padding: var(--scale_2) 20px;
    }
  }
}
